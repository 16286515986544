import * as React from "react";
import { Container } from "../../components/Container";
import { Frame } from "../../components/Frame/Frame";
import { FrameRow } from "../../components/FrameRow";
import Layout from "../../components/Layout";
import { VideoFrame } from "../../VideoFrame";
import * as styles from "../../styles/team_page.module.css";
import { graphql, Link } from "gatsby";

export const TeamPage = ({ data }) => {
  const teamMembers = data.allMdx.nodes;

  const pageContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflowX: "hidden",
    flexWrap: "wrap",
  };
  const teamContainer = {
    width: "85%",
    maxWidth: 750,
    margin: 25,
  };
  const memberContainer = {
    marginLeft: 25,
    marginRight: 25,
    width: 300,
  };
  const backedByText = {
    fontFamily: "raptor",
    fontStyle: "italic",
    fontWeight: 400,
  };

  return (
    <Layout>
      <div style={pageContainer}>
        <Container>
          <div
            style={{
              marginTop: 55,
            }}
          >
            <Frame
              firstBackground="#85ADE9"
              secondBackground="#2E538B"
              showSoundOnButton
            >
              <VideoFrame src="/videos/team (orlando).mp4" />
            </Frame>
          </div>
        </Container>

        <div style={teamContainer} className={styles.teamContainer}>
          {teamMembers.map((member) => {
            const { slug, name, title, frameColors, headshot } =
              member.frontmatter;

            return (
              <div style={memberContainer}>
                <Link
                  to={`/team/${slug}`}
                  activeClassName="active"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <FrameRow
                    width={350}
                    height={225}
                    title={name}
                    subtitle={title}
                    firstFrameColor={frameColors[0]}
                    secondFrameColor={frameColors[1]}
                    imageSrc={headshot}
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___index, order: ASC }) {
      nodes {
        frontmatter {
          slug
          name
          title
          frameColors
          headshot
        }
      }
    }
  }
`;

export default TeamPage;
